import React from 'react'

const Footer = class extends React.Component {
 render() {
   return (
     <section className="section">
       <div className="container content">
         <div className="columns">
           <div className="column has-text-centered">
            <small>Copyright &copy; 2014-2020 Andrei Gridnev</small>
          </div>
        </div>
      </div>
    </section>
  )}
}

export default Footer
