import React, {Fragment} from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from "gatsby"

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import './all.sass'

const TemplateWrapper = class extends React.Component {
  componentDidMount() {
    window.addthis
      && window.addthis.layers
      && !!window.addthis.layers.refresh
      && window.addthis.layers.refresh();
  }

  render() {
    return (
      <StaticQuery
      query={graphql`
        query HeadingQuery {
            site {
              siteMetadata {
                title,
                description,
              }
            }
          }
      `}
      render={data => (
        <Fragment>
          <Helmet>
            <html lang="en" />
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-8660221-18" />
            <script>{`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments)};gtag('js', new Date());gtag('config', 'UA-8660221-18');`}</script>
            <script src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5c3519b5968eeb87"></script>
            <title>{data.site.siteMetadata.title}</title>
            <meta name="description" content={data.site.siteMetadata.description} />
            <link rel="apple-touch-icon" sizes="180x180" href="/img/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/img/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/img/favicon-16x16.png" />
            <link rel="shortcut icon" type="image/x-icon" href="/img/favicon.ico" />
            <link rel="icon" type="image/x-icon" href="/img/favicon.ico" />

  	        <link rel="mask-icon" href="/img/safari-pinned-tab.svg" color="#ff4400" />
  	        <meta name="theme-color" content="#fff" />

  	        <meta property="og:type" content="business.business" />
            <meta property="og:title" content={data.site.siteMetadata.title} />
            <meta property="og:url" content="/" />
            <meta property="og:image" content="/img/apple-touch-icon.png" />
          </Helmet>
          <Navbar />
          <section className="section">
            <div className="container content">
              <div className="columns is-centered">
                <div className="column is-9">
                  {this.props.children}
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </Fragment>
      )}
    />);
  }
}

export default TemplateWrapper
