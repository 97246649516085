import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'

class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const postLinks = posts.map(post => (
      <div className="content" key={post.id}>
        <h2 className="post-list-title">
          <Link className="has-text-primary" to={post.node.fields.slug}>
            {post.node.frontmatter.title}
          </Link>
        </h2>
        <small>{post.node.frontmatter.date}</small>
        <p className="post-list-description">{post.node.frontmatter.description}</p>
      </div>
    ))
    const tag = this.props.pageContext.tag
    const title = this.props.data.site.siteMetadata.title
    const totalCount = this.props.data.allMarkdownRemark.totalCount
    const tagHeader = `${totalCount} post${
      totalCount === 1 ? '' : 's'
    } tagged with “${tag}”`

    return (
      <Layout>
        <Helmet>
          <title>{`Posts on ${tag} | ${title}`}</title>
          <meta name="description" content={`Posts on ${tag}`} />
        </Helmet>
        <h1>{tagHeader}</h1>
        {postLinks}
        <p><Link to="/tags/">Browse all tags</Link></p>
      </Layout>
    )
  }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            description
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`
